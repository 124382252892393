import { useLocalStorage } from '@vueuse/core'
import { defu } from 'defu'
import { useState } from '#imports'

interface LayoutOptions {
  detailsPanelWidth?: number
  sideNavMini?: boolean
}

export const useLayoutState = (options?: LayoutOptions) => {
  const opts = defu(options, {
    detailsPanelWidth: 768,
    sideNavMini: false,
  })

  const detailsPanelWidth = useLocalStorage<number>(
    '@rialtic/app-layout:detailsPanelWidth',
    opts.detailsPanelWidth,
  )
  const sideNavMini = useLocalStorage<boolean>(
    '@rialtic/app-layout:sideNavMini',
    opts.sideNavMini,
  )

  const viewWidth = useState<number>('@rialtic/app-layout:viewWidth', () => 0)
  const isScrolling = useState<boolean>(
    '@rialtic/app-layout:isScrolling',
    () => false,
  )

  const scrollEl = useState<HTMLElement | null>(
    '@rialtic/app-layout:scrollEl',
    () => null,
  )

  return {
    detailsPanelWidth,
    isScrolling,
    scrollEl,
    sideNavMini,
    viewWidth,
  }
}
